import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Container = styled.div`
  background-color: black;
  min-height: 100vh;
`

const Content = styled.div`
  color: white;
  padding: 2% 12% 6% 12%;
  margin: auto;
  max-width: 800px;

  nav {
    h2 {
      font-family: "Raleway", sans-serif;
      margin-top: 24%;
      margin-bottom: 42px;
      text-align: center;
      font-size: 42px;
      font-weight: bold;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;

      li {
        flex: 1;
        display: inline-block;

        :first-child {
          margin-right: 10%;
        }

        :last-child {
          margin-left: 10%;
        }

        h2 {
          color: black;
          font-family: "Raleway", sans-serif;
          font-weight: bold;
          font-size: 36px;
          padding: 0 36px;
          margin: 36px 0;
          text-align: left !important;
        }

        p {
          font-family: "Roboto", sans-serif;
          margin: 0 36px 36px 36px;
        }
      }
    }
  }

  @media screen and (max-width: 900px) {
    padding: 0 8% 24% 8%;

    nav { 
      h2 { 
        font-size: 28px;
        margin-bottom: 24px;
      }

      ul { 
        li { 
          :first-child  { 
            margin: 0;
          }

          :last-child { 
            margin: 0;
          }

          p { 
            margin: 0 36px;
          }

          a { 
            h2 { 
              margin: 24px 0;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
`

const Article = styled.article`
  header {
    margin-bottom: 48px;

    h1 {
      font-family: "Raleway", sans-serif;
      font-size: 42px;
      font-weight: bold;
      margin: 24px 0 0 0;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      margin: 0;
    }
  }

  section {
    h1,
    h2,
    h3,
    h4 {
      font-family: "Raleway", sans-serif;
    }

    h2 {
      font-family: "Raleway", sans-serif;
      font-size: 34px;
      font-weight: bold;
      margin: 72px 0 12px 0;
    }

    h3 {
      font-family: "Raleway", sans-serif;
      font-size: 30px;
      font-weight: bold;
      margin: 72px 0 12px 0;
    }

    p {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      line-height: 1.2;
    }
  }

  @media screen and (max-width: 900px) {
    header {
      margin-bottom: 36px;

      h1 {
        font-size: 32px;
        line-height: 1.1;
        margin: 0 0 12px 0;
      }

      p {
        font-size: 16px;
        font-weight: 300;
      }
    }

    section {
      h2 { 
        font-size: 28px;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.12;
      }
    }
  }
`

const OtherPostContainer = styled(Link)`
  background-color: white;
  border-radius: 12px;
  text-decoration: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  color: black;
  margin: auto;
  width: 100%;
  max-width: 800px;
  height: 100%;

  p {
    font-size: 18px;
    font-weight: 300;
  }

  @media screen and (max-width: 900px) {
    margin: 24px 0;
    width: 100%;

    h2 {
      font-size: 24px;
    }

    p {
      font-size: 16px;
    }
  }
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  console.log("previous ", previous)
  console.log("next ", next)
  console.log("pageContext ", pageContext)

  return (
    <Layout
      location={location}
      title={siteTitle}
      heroTitle="The Clink.Social Blog"
      heroSubtitle=""
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container>
        <Content>
          <Article>
            <header>
              <h1>{post.frontmatter.title}</h1>
              <p>{post.frontmatter.author}</p>
              <p>{post.frontmatter.date}</p>
            </header>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </Article>
          <nav>
            <h2>Other posts from the Clink team</h2>
            <ul>
              {previous && (
                <li>
                  <OtherPostContainer to={previous.fields.slug} rel="next">
                    <h2>{previous.frontmatter.title}</h2>
                    <p>{previous.frontmatter.description}</p>
                  </OtherPostContainer>
                </li>
              )}
              {next && (
                <li>
                  <OtherPostContainer to={next.fields.slug} rel="next">
                    <h2>{next.frontmatter.title}</h2>
                    <p>{next.frontmatter.description}</p>
                  </OtherPostContainer>
                </li>
              )}
            </ul>
          </nav>
        </Content>
      </Container>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        author
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
